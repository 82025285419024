@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;350;400;500;600;700;800;900&display=swap');
@layer base {
  html {
    font-family: Open Sans, system-ui, sans-serif;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
